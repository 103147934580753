<div id="cs-2" class="section msg-section">
  <h2 class="reqiured-section-header">聯絡人資訊</h2>
  <div
    class="form-control"
    style="margin-top: 0.75rem; margin-bottom: 1.5rem"
  >
    <label
      for="contact-name-2"
      class="reqiured-label"
    >姓名</label>
    <hr />
    <div style="display: flex; flex-direction: row; width: 100%; align-items: center;">
      <chtapp-input
        id="contact-name-2"
        placeholder="輸入中文姓名"
        type="text"
        required="true"
        name=""
        style="width: 200px; flex-grow: 1;"
      ></chtapp-input>
      <div style="padding: 0 8px;">
        <chtapp-radio
          id="contact-title-mister-2"
          name="contact-title-2"
          value="先生"
          text="先生"
          cancelable="true"
        ></chtapp-radio>
        <chtapp-radio
          id="contact-title-miss-2"
          name="contact-title-2"
          value="小姐"
          text="小姐"
          cancelable="true"
        ></chtapp-radio>
      </div>
    </div>
  </div>
  <div class="form-control" style="margin-bottom: 1.25rem">
    <label for="name" class="reqiured-label">聯絡方式</label>
  </div>
  <div class="form-control" style="padding-left: 0.25rem">
    <label
      for="contact-phone-2"
      class="reqiured-label"
    >行動電話號碼</label>
    <hr />
    <chtapp-input
      id="contact-phone-2"
      placeholder="輸入聯絡號碼"
      type="tel"
      reg-validator="^09\d{8}$"
      name="contact-phone-2"
      required="true"
      maxlength="10"
      style="width: 100%;"
    ></chtapp-input>
    <hr />
    <small>
      客服人員會透過此號碼與您聯絡
    </small>
  </div>
  <div class="form-control" style="padding-left: 0.25rem">
    <label
      for="contact-email-2"
      class="reqiured-label"
    >電子信箱</label>
    <hr />
    <chtapp-input
      id="contact-email-2"
      placeholder="輸入電子信箱"
      type="email"
      name="contact-email-2"
      required="true"
      reg-validator="^(?:(\&quot;)(\&quot;[^\&quot;]+?\&quot;@)|(([0-9a-zA-Z]((\.(?!\.))|[-!#\$%&&apos;\*\+/=\?\^`\{\}\|~\w])*)(?<=[0-9a-zA-Z])@))(?:(\[)(\[(\d{1,3}\.){3}\d{1,3}\])|(([0-9a-zA-Z][-\w]*[0-9a-zA-Z]*\.)+[a-zA-Z0-9]{2,17}))$"
      style="width: 100%;"
    ></chtapp-input>
  </div>
</div>