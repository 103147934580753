<div id="online-cs">
  <div class="fabs">
    <div id="inner-window" class="chat" style="display:none;">
      <div class="chat-header">
        <div class="chat-option">
          <div id="inner-back" class="back">
            <img src="{{endpoint}}/img/icon/left-arrow.svg" alt="" />
          </div>
          <div id="chat-head">
            <h1>{{chatName}}</h1>
          </div>
        </div>
      </div>
      <div id="inner-frame" class="chat-channel">
        <iframe id="spa-frame" frameborder="0" class="scroll-width-thin"></iframe>
      </div>
    </div>
    <div id="main-chat" class="chat" style="display:none;">
      <div class="chat-header">
        <div class="chat-option">
          <div id="chat-head">
            <h1>{{chatName}}</h1>
            <div class="chat-head-icon">
              <span id="chat-animate">
                <i id="connecting" class='zmdi zmdi-phone-in-talk'></i>
                <i id="loading" class="zmdi zmdi-spinner"></i>
              </span>
            </div>
          </div>
          <div class="header-left">
            <chtapp-tooltip text="最小化" id="chat-minimize">
              <img src="{{endpoint}}/img/icon/minimize.svg" class="icon" alt="" />
            </chtapp-tooltip>
            <div id="chat-fullscreen-loader">
              <chtapp-tooltip text="展開視窗">
                <img src="{{endpoint}}/img/icon/expand.svg" class="icon launch" alt="" />
              </chtapp-tooltip>
              <chtapp-tooltip text="縮小視窗">
                <img src="{{endpoint}}/img/icon/ufold-less.svg" class="icon launch-off" alt="" />
              </chtapp-tooltip>
            </div>
          </div>
          <div class="header-right">
            <div id="chat-disconnect" class="chat-disconnect is-hidden">
              <span>結束對談</span>
            </div>
            <div id="chat-call" class="call-bot is-hidden">
              <span>重啟對談</span>
            </div>
          </div>
        </div>
      </div>
      <div class="chat-body">
        <div id="main-frame" class="chat-converse">
          <iframe id="chat-frame" src="{{iframe}}" frameborder="0" allow="clipboard-read; clipboard-write"
            class="scroll-width-thin" style="height:100%;width:100%;"></iframe>
        </div>
      </div>
      <div class="chat-footer">
        <chat-menu id="shortcut-menu" view-type="embed" style="display: none;"></chat-menu>
        <div class="fab-field">
          <div class="btn-gourp-left">
            <chtapp-appbtn id="fab-apps" class="fab" view-type="embed"></chtapp-appbtn>
            <a id="fab-camera" class="fab" style="display: none;"><i class="zmdi zmdi-camera"></i></a>
            <chtapp-fontsize-popup id="fab-font-size" class="fab" view-type="embed"></chtapp-fontsize-popup>
          </div>
          <textarea id="chatSend" name="chat-message" maxlength="60" placeholder="請在20字內簡述您的問題"
            class="chat-field chat-message"></textarea>
          <div class="btn-gourp-right">
            <a id="fab-send" class="fab"><i class="zmdi zmdi-mail-send"></i></a>
          </div>
        </div>

      </div>
    </div>
    <div id="prime" class="btn-customer-service">
      <div class="btn-avatar">
        <img class="btn-avatar-img" src="{{endpoint}}/img/icon-chatbot-02.webp" />
      </div>
      <div class="btn-close">
        <i class="zmdi zmdi-close zmdi-hc-lg icon"></i>
      </div>
      {{!-- <i class="prime zmdi zmdi-headset-mic"></i> --}}
    </div>
  </div>
</div>