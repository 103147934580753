<div id="hinet-ci-5" class="section msg-section" style="display: none;">
  <h2 class="reqiured-section-header">客戶資訊</h2>
  <small>
    為提供服務的完整性，需請您協助填寫以下資訊(若資訊不完整或不正確，可能無法處理您的留言/簡訊需求)
  </small>
  <br />
  <div class="form-control">
    <label
      for="username5"
      class="reqiured-label"
    >姓名</label>
    <hr />
    <div style="display: flex; flex-direction: row; width: 100%; align-items: center;">
      <chtapp-input
        id="username5"
        placeholder="輸入中文姓名"
        type="text"
        required="true"
        name=""
        style="width: 200px; flex-grow: 1;"
      ></chtapp-input>
      <div style="padding: 0 8px;">
        <chtapp-radio
          id="user-title-mister-5"
          name="user-title-5"
          value="先生"
          text="先生"
          cancelable="true"
        ></chtapp-radio>
        <chtapp-radio
          id="user-title-miss-5"
          name="user-title-5"
          value="小姐"
          text="小姐"
          cancelable="true"
        ></chtapp-radio>
      </div>
    </div>
  </div>
  <div class="form-control">
    <label
      for="hn_device5"
      class="reqiured-label"
    >設備號碼</label>
    <span class="comment-right">
      <a href="#" class="deviceid-in-bill">
        <i class="zmdi zmdi-help-outline"></i> 設備號碼範例
      </a>
    </span>
  </div>
  <div class="form-control" style="margin-top: 0.25rem">
    <chtapp-input
      id="hn_device5"
      placeholder="輸入市話/寬頻/HN號碼"
      type="text"
      required="true"
      name=""
      style="width: 200px; flex-grow: 1;"
    ></chtapp-input>
  </div>
  <div class="form-control">
    <chtapp-address
      id="install-address5"
      name="install-address5"
      text="裝機地址"
      required="true"
      style="width: 100%;"
    ></chtapp-address>
  </div>
  <div class="form-control">
    <label
      for="id-no5"
      class="reqiured-label"
    >身分證號後四碼</label>
    <hr />
    <chtapp-input
      id="id-no5"
      placeholder="輸入身分證後四碼"
      type="tel"
      reg-validator="^[0-9]{4}$"
      maxlength="4"
      name=""
      style="width: 200px; flex-grow: 1"
    />
  </div>
  <div class="form-control">
    <label for="birth-date5" class="reqiured-label">出生年月日</label>
    <hr />
    <chtapp-date-input id="birth-date5"></chtapp-date-input>
  </div>
  <div style="margin-top: 0.75rem;">
    <label for="id-renew-date-5" class="reqiured-label">國民身分證領補換資料</label>
    <hr />
    <div style="display: flex; gap: 12px; flex-wrap: wrap;">
      <chtapp-date-input id="id-renew-date-5"></chtapp-date-input>
      <div style="display: flex; gap: 12px;">
        <chtapp-dropdown
          id="id-renew-location-5"
          placeholder="發證地點"
          width="130"
          maxitems="5"
          options='[
            { "name": "北縣", "value": "北縣" },
            { "name": "宜縣", "value": "宜縣" },
            { "name": "桃縣", "value": "桃縣" },
            { "name": "竹縣", "value": "竹縣" },
            { "name": "苗縣", "value": "苗縣" },
            { "name": "中縣", "value": "中縣" },
            { "name": "彰縣", "value": "彰縣" },
            { "name": "投縣", "value": "投縣" },
            { "name": "雲縣", "value": "雲縣" },
            { "name": "嘉縣", "value": "嘉縣" },
            { "name": "南縣", "value": "南縣" },
            { "name": "高縣", "value": "高縣" },
            { "name": "屏縣", "value": "屏縣" },
            { "name": "東縣", "value": "東縣" },
            { "name": "花縣", "value": "花縣" },
            { "name": "澎縣", "value": "澎縣" },
            { "name": "基市", "value": "基市" },
            { "name": "竹市", "value": "竹市" },
            { "name": "嘉市", "value": "嘉市" },
            { "name": "連江", "value": "連江" },
            { "name": "金門", "value": "金門" },
            { "name": "北市", "value": "北市" },
            { "name": "高市", "value": "高市" },
            { "name": "新北市", "value": "新北市" },
            { "name": "中市", "value": "中市" },
            { "name": "南市", "value": "南市" },
            { "name": "桃市", "value": "桃市" }
          ]'
        ></chtapp-dropdown>
        <chtapp-dropdown
          id="id-renew-type-5"
          placeholder="領補換類別"
          width="130"
          options='[
            { "name": "初發", "value": "初發" },
            { "name": "補發", "value": "補發" },
            { "name": "換發", "value": "換發" }
          ]'
        ></chtapp-dropdown>
      </div>
    </div>
  </div>
  <div class="form-control">
    <label for="request-year-month-5" class="reqiured-label">需調閱之年月</label>
    <hr />
    <chtapp-year-month id="request-year-month-5"></chtapp-year-month>
  </div>
  <div class="form-control">
    <chtapp-checkbox id="approve-self5" required="true">
      <span slot="label-text">本人申辦</span>
    </chtapp-checkbox>
    <small ref="isselfDesciption" style="padding-left: 2rem;">
      為保護資料安全，申辦、查詢須為本人，若非本人請代辦人攜帶雙方證件臨櫃辦理。
    </small>
  </div>
</div>
