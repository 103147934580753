<div id="mobile-ci-1" class="section msg-section">
  <h2 class="reqiured-section-header">客戶資訊</h2>
  <small>
    為提供服務的完整性，需請您協助填寫以下資訊(若資訊不完整或不正確，可能無法處理您的留言/簡訊需求)
  </small>
  <br />
  <div class="form-control">
    <label
      for="username1"
      class="reqiured-label"
    >姓名</label>
    <hr />
    <chtapp-input
      id="username1"
      placeholder="輸入中文姓名"
      type="text"
      required="true"
      name=""
      style="width: 200px; flex-grow: 1;"
    ></chtapp-input>
    <span class="postfix">(先生/小姐)</span>
  </div>
  <div class="form-control">
    <label
      for="phone1"
      class="reqiured-label"
    >行動號碼</label>
    <hr />
    <chtapp-input
      id="phone1"
      placeholder="輸入行動號碼"
      type="tel"
      reg-validator="^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$"
      name=""
      maxlength="10"
      style="width: 200px; flex-grow: 1"
    ></chtapp-input>
  </div>
  <div class="form-control">
    <label
      for="id-no1"
      class="reqiured-label"
    >身分證號後四碼</label>
    <hr />
    <chtapp-input
      id="id-no1"
      placeholder="輸入身分證後四碼"
      type="tel"
      reg-validator="^[0-9]{4}$"
      maxlength="4"
      name=""
      style="width: 200px; flex-grow: 1"
    ></chtapp-input>
  </div>
  <div class="form-control">
    <label for="birth-date" class="reqiured-label">出生年月日</label>
    <hr />
    <chtapp-date-input id="birth-date"></chtapp-date-input>
  </div>
  <div class="form-control">
    <chtapp-checkbox id="approve-self1" required="true">
      <span slot="label-text">本人申辦</span>
    </chtapp-checkbox>
    <small ref="isselfDesciption" style="padding-left: 2rem;">
      為保護資料安全，申辦、查詢須為本人，若非本人請代辦人攜帶雙方證件臨櫃辦理。
    </small>
  </div>
</div>
