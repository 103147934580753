import fontStyle from '../../scss/fonts.scss'

let getElementById = (selector: string) => {
  return document.getElementById(selector)
}
let getElementsByClassName = (selector: string) => {
  return document.getElementsByClassName(selector)
}

export default class AppBtn extends HTMLElement {
  _setupClickOutsideEvent: boolean
  _iframeElement: HTMLIFrameElement | null
  _disabled: boolean

  constructor() {
    super()
    this.attachShadow({ mode: 'open' })

    this.handlePopup = this.handlePopup.bind(this)
    this.checkClickOutside = this.checkClickOutside.bind(this)

    this._setupClickOutsideEvent = false
    this._iframeElement = null
    this._disabled = false
  }

  connectedCallback() {
    if (this.shadowRoot !== null) {
      // DOM
      this.shadowRoot.innerHTML = this.template()
      const rootElement = document.getElementById('chat-web-component')
      if (rootElement !== null) {
        getElementById = (selector) => {
          if (rootElement.shadowRoot !== null && rootElement.shadowRoot !== undefined) {
            return rootElement.shadowRoot.getElementById(selector)
          } else {
            return null
          }
        }
        getElementsByClassName = (selector) => {
          if (rootElement.shadowRoot !== null && rootElement.shadowRoot !== undefined) {
            const els = rootElement.shadowRoot.querySelectorAll(`.${selector}`)
            if (els !== null) {
              return <HTMLCollectionOf<Element>>(<unknown>Array.from(els))
            }
          }
          return <HTMLCollectionOf<Element>>(<unknown>Array.from([]))
        }
      }
      this._iframeElement = getElementById('chat-frame') as HTMLIFrameElement

      // Styles
      const styleElement = document.createElement('style')
      styleElement.appendChild(document.createTextNode(fontStyle))
      this.shadowRoot.appendChild(styleElement)
      const iconElement = this.shadowRoot.querySelector('i')
      if (iconElement !== null) {
        if (this.getAttribute('view-type') === 'app') {
          iconElement.classList.add('gray')
        }
      }

      // Events
      this.addEventListener('click', this.handlePopup)
      window.addEventListener('click', this.checkClickOutside, { capture: true })
    }
  }

  disconnectedCallback() {}

  handlePopup() {
    const shortcutMenu = getElementById('shortcut-menu')
    if (this.shadowRoot !== null && !this._disabled) {
      if (this.getAttribute('view-type') === 'embed' && shortcutMenu !== null) {
        if (shortcutMenu.getAttribute('data-display') === 'block') {
          shortcutMenu.style.display = 'none'
          shortcutMenu.setAttribute('data-display', 'none')
        } else {
          this.dispatchEvent(new CustomEvent('show-menu'))
          shortcutMenu.style.display = 'block'
          shortcutMenu.setAttribute('data-display', 'block')
        }
      } else if (this.getAttribute('view-type') === 'app') {
        if (getElementsByClassName('body-right')[0] !== undefined) {
          const bodyRightElement = getElementsByClassName('body-right')[0] as HTMLElement
          if (getComputedStyle(bodyRightElement).display === 'none') {
            this.dispatchEvent(new CustomEvent('show-menu'))
            bodyRightElement.style.display = 'block'
          } else {
            bodyRightElement.style.display = 'none'
          }
        }
      } else {
        if (getElementsByClassName('body-right')[0] !== undefined) {
          const bodyRightElement = getElementsByClassName('body-right')[0] as HTMLElement
          if (getComputedStyle(bodyRightElement).display === 'none') {
            this.dispatchEvent(new CustomEvent('show-menu'))
            bodyRightElement.style.display = 'block'
          } else {
            bodyRightElement.style.display = 'none'
          }
        }
      }
    }
    if (
      !this._setupClickOutsideEvent &&
      this._iframeElement !== null &&
      this._iframeElement.contentWindow !== null
    ) {
      this._setupClickOutsideEvent = true
      this._iframeElement.contentWindow.addEventListener('click', this.checkClickOutside)
    }
  }

  hidePopup() {
    const shortcutMenu = getElementById('shortcut-menu')
    if (this.shadowRoot !== null) {
      if (this.getAttribute('view-type') === 'embed' && shortcutMenu !== null) {
        shortcutMenu.style.display = 'none'
        shortcutMenu.setAttribute('data-display', 'none')
      } else {
        if (getElementsByClassName('body-right')[0] !== undefined) {
          const bodyRightElement = getElementsByClassName('body-right')[0] as HTMLElement
          bodyRightElement.style.display = 'none'
        }
      }
    }
  }

  checkClickOutside(e: MouseEvent | TouchEvent) {
    if (this.shadowRoot !== null) {
      const shortcutMenu = getElementById('shortcut-menu')
      const target = e.composedPath()[0]
      if (target !== undefined) {
        if (shortcutMenu !== null) {
          if (
            !shortcutMenu.contains(target as HTMLElement) &&
            !this.shadowRoot.contains(target as HTMLElement)
          ) {
            this.hidePopup()
          }
        }
      }
    }
  }

  set disabled(d: boolean) {
    this._disabled = d
  }

  get disabled() {
    return this._disabled
  }

  template() {
    return `
    <div>
      <style>
        i { font-size: 24px; line-height: 36px; color: #ccc; }
        i.gray { color: #666; }
        i:hover { color: #209cff;}
      </style>
      <i class="zmdi zmdi-apps" style="font-size: 24px; line-height: 36px;"></i>
    </div>
    `
  }
}
