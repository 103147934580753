<div class="chat-header">
  <div class="chat-bar"></div>
  <div class="chat-option">
    <div id="chat-head">
      <h1>留言給客服</h1>
    </div>
    <div id="chat-disconnect" class="chat-disconnect">
      <i class="zmdi zmdi-close zmdi-hc-lg"></i>
    </div>
  </div>
</div>