import '@webcomponents/webcomponentsjs/webcomponents-bundle'
import '@webcomponents/webcomponentsjs/custom-elements-es5-adapter.js'
import '@webcomponents/custom-elements/custom-elements.min.js'
import fontStyle from '../scss/fonts.scss'

import '../components/wcEmbed'
import '../components/wcMenu'
import '../components/Alerts/wcPrivacyAlert'
import setupComponentElements from '../components/Components'

class Main {
  constructor() {
    setupComponentElements()
    this.loadApp()
  }

  async loadApp() {
    // main flow
    try {
      await this.genTemplate()
    } catch (error) {
      console.error(error)
    }
  }

  loadInlineJS(src: string) {
    return new Promise((resolve, reject) => {
      const headTag = document.getElementsByTagName('head')[0]
      const jsTag = document.createElement('script')
      jsTag.type = 'text/javascript'
      jsTag.src = src
      jsTag.onload = () => resolve(1)
      headTag.appendChild(jsTag)
    })
  }

  async genTemplate() {
    const env = '{{DEVELOPMENT}}'
    const headTag = document.getElementsByTagName('head')[0]
    const styleElement = document.createElement('style')
    styleElement.appendChild(document.createTextNode(fontStyle))
    const bodyTag = document.getElementsByTagName('body')[0]
    const selector = 'chat-web-component'
    const div = document.createElement('chat-embed')
    div.id = selector
    bodyTag.appendChild(div)
    console.debug('element id: ' + selector)
    console.debug('view type: embed')
    headTag.appendChild(styleElement)
    if (env === 'production') {
      const script = document.createElement('script')
      script.textContent = `(function (w, d, s, l, i) {
              w[l] = w[l] || []; w[l].push({
                'gtm.start':
                  new Date().getTime(), event: 'gtm.js'
              }); var f = d.getElementsByTagName(s)[0],
                j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
                  'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
            })(window, document, 'script', 'dataLayer', 'GTM-MPDJS5Z');`
      headTag.appendChild(script)
      const noscript = document.createElement('noscript')
      noscript.textContent =
        '<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-MPDJS5Z" height="0" width="0" style="display:none;visibility:hidden"></iframe>'
      bodyTag.appendChild(noscript)
    }
  }
}

export default new Main()
