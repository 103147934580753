<div class="section msg-section">
  <h2 class="reqiured-section-header">選擇問題類型</h2>
  <div class="form-control">
    <label for="enterprise-business-choose">詢問業務</label>
    <chtapp-dropdown
      id="enterprise-business-choose"
      class="dropdown"
      selectindex="0"
      options='[
        { "id": 103, "name": "企客解決方案", "value": "企客解決方案" },
        { "id": 101, "name": "行動業務", "value": "行動業務" },
        { "id": 102, "name": "寬頻/市話/MOD", "value": "寬頻/市話/MOD" }
      ]'>
    </chtapp-dropdown>
  </div>
  <div class="form-control">
    <label for="question" @click="setFocus('question')">問題類別</label>
    <chtapp-dropdown
      id="enterprise-question"
      placeholder="請選擇項目"
      class="dropdown"
      maxitems="5"
      options='[
        { "id": 0, "name": "業務諮詢​", "value": "業務諮詢​" },
        { "id": 1, "name": "申租異動", "value": "申租異動" },
        { "id": 2, "name": "補寄帳單", "value": "補寄帳單" },
        { "id": 3, "name": "障礙問題", "value": "障礙問題" }
      ]'
    ></chtapp-dropdown>
  </div>
</div>
