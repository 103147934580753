<div id="mobile-ci-2" class="section msg-section" style="display: none;">
  <h2>客戶資訊</h2>
  <small>
    為提供服務的完整性，需請您協助填寫以下資訊(若資訊不完整或不正確，可能無法處理您的留言/簡訊需求)
  </small>
  <br />
  <div class="form-control">
    <label for="phone2">行動號碼</label>
    <hr />
    <chtapp-input
      id="phone2"
      placeholder="輸入行動號碼"
      type="tel"
      name=""
      maxlength="10"
      style="width: 200px; flex-grow: 1"
    ></chtapp-input>
  </div>
</div>
