<div id="mobile-ci-3" class="section msg-section" style="display: none;">
  <h2 class="reqiured-section-header">客戶資訊</h2>
  <small>
    為提供服務的完整性，需請您協助填寫以下資訊(若資訊不完整或不正確，可能無法處理您的留言/簡訊需求)
  </small>
  <br />
  <div class="form-control">
    <label
      for="username3"
      class="reqiured-label"
    >姓名</label>
    <hr />
    <chtapp-input
      id="username3"
      placeholder="輸入中文姓名"
      type="text"
      required="true"
      name=""
      style="width: 200px; flex-grow: 1;"
    ></chtapp-input>
    <span class="postfix">(先生/小姐)</span>
  </div>
  <div class="form-control">
    <label
      for="phone3"
      class="reqiured-label"
    >行動號碼</label>
    <hr />
    <chtapp-input
      id="phone3"
      placeholder="輸入行動號碼"
      type="tel"
      reg-validator="^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$"
      name=""
      maxlength="10"
      style="width: 200px; flex-grow: 1"
    ></chtapp-input>
  </div>
  <div class="form-control">
    <label for="fail-date" class="reqiured-label">障礙發生日期</label>
    <hr />
    <chtapp-date-input id="fail-date"></chtapp-date-input>
  </div>
  <div class="form-control">
    <chtapp-address
      id="fail-address3"
      name="fail-address3"
      text="障礙發生詳細地址"
      required="true"
      style="width: 100%;"
    ></chtapp-address>
  </div>
  <div class="form-control">
    <label for="signal-strength3" class="reqiured-label">訊號格數</label>
    <hr>
    <chtapp-dropdown
      id="signal-strength3"
      placeholder="選擇訊號格數"
      maxitems="5"
      class="dropdown"
      style="max-width: 208px"
      options='[
        { "id": 0, "name": "4~5格", "value": "4~5格" },
        { "id": 1, "name": "3~4格", "value": "3~4格" },
        { "id": 2, "name": "2~3格", "value": "2~3格" },
        { "id": 3, "name": "1~2格", "value": "1~2格" },
        { "id": 4, "name": "無服務", "value": "無服務" }
      ]'
    ></chtapp-dropdown>
  </div>
  <div class="form-control">
    <label
      for="device-model3"
      class="reqiured-label"
    >手機機型</label>
    <hr />
    <chtapp-input
      id="device-model3"
      placeholder="輸入手機機型，如: iPhone 12"
      type="text"
      required="true"
      name=""
      style="width: 200px; flex-grow: 1"
    ></chtapp-input>
  </div>
</div>
