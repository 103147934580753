<div id="bs-3" class="section msg-section business-block">
  <h2 class="reqiured-section-header" style="display: inline;">業務項目</h2>
  <span class="postfix">(可複選)</span>
  <div class="form-control checkbox-group">
    <chtapp-checkbox name="businesses-3[]" value="HiNet光世代固定制">
      <span slot="label-text">HiNet光世代固定制</span>
    </chtapp-checkbox>
    <chtapp-checkbox name="businesses-3[]" value="HiNet網際傳真">
      <span slot="label-text">HiNet網際傳真</span>
    </chtapp-checkbox>
    <chtapp-checkbox name="businesses-3[]" value="hiBox全能信箱">
      <span slot="label-text">hiBox全能信箱</span>
    </chtapp-checkbox>
    <chtapp-checkbox name="businesses-3[]" value="Microsoft 365">
      <span slot="label-text">Microsoft 365</span>
    </chtapp-checkbox>
    <chtapp-checkbox name="businesses-3[]" value="域名註冊">
      <span slot="label-text">域名註冊</span>
    </chtapp-checkbox>
    <chtapp-checkbox name="businesses-3[]" value="UC雲端總機">
      <span slot="label-text">UC雲端總機</span>
    </chtapp-checkbox>
    <chtapp-checkbox name="businesses-3[]" value="hiHosting企業網路架站">
      <span slot="label-text">hiHosting企業網路架站</span>
    </chtapp-checkbox>
    <chtapp-checkbox name="businesses-3[]" value="行動固定IP上網服務">
      <span slot="label-text">行動固定IP上網服務</span>
    </chtapp-checkbox>
    <chtapp-checkbox name="businesses-3[]" value="HiLink VPN企業專屬網路">
      <span slot="label-text">HiLink VPN企業專屬網路</span>
    </chtapp-checkbox>
    <chtapp-checkbox name="businesses-3[]" value="0800受話方付費電話">
      <span slot="label-text">0800受話方付費電話</span>
    </chtapp-checkbox>
    <chtapp-checkbox name="businesses-3[]" value="其他">
      <span slot="label-text">其他</span>
    </chtapp-checkbox>
  </div>
  <div class="form-control" style="margin-top: 0.25rem; padding-left: 1.95rem;">
    <chtapp-input
      id="business-nameinput-3"
      placeholder="請自行輸入產品名稱"
      type="text"
      name=""
      style="width: 100%;"
    ></chtapp-input>
  </div>
</div>
