import $$ from 'jquery'
import menuTemplate from '../../handlebars/components/menu.hbs'
import menuStyle from '../scss/menu.scss'
import embedStyle from '../scss/embed.scss'
import { Controller } from '../modules/controller'
interface itemAction {
  cmd: Action
  value: (() => void) | string
  text?: string
}

interface menuItem {
  id?: string
  order: number
  text: string
  action: itemAction | null
  disabled: boolean
  img: string
}

let $: JQueryStatic | ((selector: string) => JQuery<HTMLElement>) = $$
let getElementById = (selector: string) => {
  return document.getElementById(selector)
}

class Menu extends HTMLElement {
  _menuDict: {
    Mobile: menuItem[]
    FixedLine: menuItem[]
  }
  _items: menuItem[]
  _disabled: boolean
  _iframeElement: HTMLIFrameElement | null
  _controller: Controller | null
  _businessId: 'Mobile' | 'FixedLine'

  constructor() {
    super()
    this.attachShadow({ mode: 'open' })

    this.fetchData = this.fetchData.bind(this)

    this._businessId = 'FixedLine'
    this._menuDict = {
      Mobile: [],
      FixedLine: []
    }
    this._items = []
    this.resetItems()
    this._disabled = true
    this._iframeElement = null
    this._controller = null
  }

  async connectedCallback() {
    const rootElement = document.getElementById('chat-web-component')
    if (rootElement !== null) {
      $ = (selector: string) => {
        if (rootElement.shadowRoot !== null && rootElement.shadowRoot !== undefined) {
          return $$(rootElement.shadowRoot).find(selector)
        } else {
          return $$(document).find(selector)
        }
      }
      getElementById = (selector) => {
        if (rootElement.shadowRoot !== null && rootElement.shadowRoot !== undefined) {
          return rootElement.shadowRoot.getElementById(selector)
        } else {
          return null
        }
      }
    }
    this._iframeElement = getElementById('chat-frame') as HTMLIFrameElement

    // fetch and render menu
    await this.fetchData()
    this.resetItems()
    Object.assign(this._items, this._menuDict[this._businessId])
    this.render()
  }

  doAction(event: Event) {
    // 因為是在 capturing phase 監聽事件，停止向下傳遞事件
    event.stopPropagation()
    if (!this._disabled) {
      const target = event.currentTarget
      if (target && target instanceof Element) {
        const targetId = String(target.id)
        if (Array.isArray(this._items)) {
          const clickedItem = this._items.find((element) => element.id === targetId)
          if (clickedItem) {
            if (typeof clickedItem.action === 'object' && clickedItem.action !== null) {
              switch (clickedItem.action.cmd) {
                case 'AGENT':
                  if (this._controller !== null) {
                    this._controller.callAgent()
                    this._controller.frameSend({
                      _service: 'chtbot-js',
                      type: 'clicktracking',
                      data: `#快捷-轉真人-${clickedItem.text}`
                    })
                  }
                  break
                case 'TRAN':
                  if (
                    typeof clickedItem.action.value === 'string' &&
                    ['Mobile', 'FixedLine'].includes(clickedItem.action.value)
                  ) {
                    if (this._controller !== null) {
                      this._controller.transferTo(
                        clickedItem.action.value,
                        String(clickedItem.action.text)
                      )
                      this._controller.frameSend({
                        _service: 'chtbot-js',
                        type: 'clicktracking',
                        data: `#快捷-切換業務-${clickedItem.text}-${
                          clickedItem.action.value === 'Mobile' ? '行動' : '非行動'
                        }`
                      })
                    }
                  }
                  break
                case 'FUNCTION':
                  if (typeof clickedItem.action.value === 'function') {
                    clickedItem.action.value()
                  }
                  break
                case 'GOTO':
                  if (clickedItem.action.value === '99003') {
                    if (this.getAttribute('view-type') === 'app') {
                      if (this._controller !== null) {
                        this._controller.action(clickedItem.action)
                      }
                    } else {
                      if (this._controller !== null) {
                        this._controller.action({
                          cmd: 'WEB',
                          value: 'https://my.cht.com.tw'
                        })
                      }
                    }
                  } else if (clickedItem.action.value === 'about') {
                    this.openInfoWindow()
                    if (this._controller !== null) {
                      this._controller.frameSend({
                        _service: 'chtbot-js',
                        type: 'clicktracking',
                        data: `#快捷-直穿-${clickedItem.text}`
                      })
                    }
                  } else {
                    if (this._controller !== null) {
                      this._controller.action(clickedItem.action)
                      this._controller.frameSend({
                        _service: 'chtbot-js',
                        type: 'clicktracking',
                        data: `#快捷-直穿-${clickedItem.text}-${clickedItem.action.value}`
                      })
                    }
                  }
                  break
                case 'SEND':
                  if (typeof clickedItem.action.text === 'string') {
                    if (this._controller !== null && this._controller.$textInput !== null) {
                      const intendId =
                        typeof clickedItem.action.value === 'string' ? clickedItem.action.value : ''
                      this._controller.$textInput.val(clickedItem.action.text)
                      this._controller.send(clickedItem.action.text, intendId)
                      this._controller.frameSend({
                        _service: 'chtbot-js',
                        type: 'clicktracking',
                        data: `#快捷-意圖-${clickedItem.text}-${clickedItem.action.value}`
                      })
                    }
                  }
                  break
                default:
                  if (this._controller !== null) {
                    this._controller.action(clickedItem.action)
                    if (['WEB', 'WebOpenInApp'].includes(clickedItem.action.cmd)) {
                      this._controller.frameSend({
                        _service: 'chtbot-js',
                        type: 'clicktracking',
                        data: `#快捷-外開網頁-${clickedItem.text}-${clickedItem.action.value}`
                      })
                    }
                  }
                  break
              }

              // hide menu of small screen after click
              const fabElement = getElementById('fab-apps')
              const shortcutMenu = getElementById('shortcut-menu')
              if (fabElement !== null && shortcutMenu !== null) {
                if (getComputedStyle(shortcutMenu).display !== 'none') {
                  ;(fabElement as any).hidePopup()
                }
              }
            }
          }
        }
      }
    }
  }

  resetItems() {
    this._items = []
  }

  fetchData() {
    const _self = this
    return new Promise((resolve) => {
      fetch('{{SERVICE}}/server/dataset/panel')
        .then((response) => response.json())
        .then(function (json) {
          if ('Mobile' in json && 'FixedLine' in json) {
            _self._menuDict = json as {
              Mobile: menuItem[]
              FixedLine: menuItem[]
            }
          }
          resolve(1)
        })
        .catch((error) => {
          console.debug(error)
          resolve(0)
        })
    })
  }

  get businessId() {
    return this._businessId
  }

  set businessId(bid: 'Mobile' | 'FixedLine') {
    this._businessId = bid
    this.resetItems()
    Object.assign(this._items, this._menuDict[bid])
    this.render()
  }

  get controller() {
    return null
  }

  set controller(c: Controller | null) {
    this._controller = c
  }

  get disabled() {
    return this._disabled
  }

  set disabled(val) {
    this._disabled = Boolean(val)
    if (this.shadowRoot) {
      if (this._disabled) {
        this.shadowRoot.getElementById('menu-root')?.classList.add('disabled')
      } else {
        this.shadowRoot.getElementById('menu-root')?.classList.remove('disabled')
      }
    }
  }

  render() {
    if (this.shadowRoot !== null) {
      // DOM
      const data: {
        firstRowItems: menuItem[]
        secondRowItems: menuItem[]
        genClass: string
      } = {
        firstRowItems: [],
        secondRowItems: [],
        genClass: ''
      }
      if (Array.isArray(this._items)) {
        this._items.sort((a, b): number =>
          typeof a === 'object' && typeof b === 'object' ? a.order - b.order : 1
        )
        this._items.forEach((item, index) => {
          item.id = `menu-item-${index}`
        })
        const firstLength =
          this._items.length < 4 ? this._items.length : Math.floor(this._items.length / 2.0)
        data.firstRowItems = this._items.slice(0, firstLength)
        data.secondRowItems = this._items.slice(firstLength, this._items.length)
        const classes = ['shortcut-menu']
        if (this.hasAttribute('view-type') && this.getAttribute('view-type') === 'embed') {
          classes.push('embed-menu')
        }
        if (this._disabled) {
          classes.push('disabled')
        }
        data.genClass = classes.join(' ')
      }
      this.shadowRoot.innerHTML = menuTemplate(data)

      // Style
      const styleElement = document.createElement('style')
      styleElement.appendChild(document.createTextNode(menuStyle))
      styleElement.appendChild(document.createTextNode(embedStyle))
      this.shadowRoot.appendChild(styleElement)

      // Bind menu buttons' event
      this.doAction = this.doAction.bind(this)
      this.shadowRoot.querySelectorAll('.menu-item').forEach((item) => {
        // 將第三個參數設成 true 以在 capturing phase 監聽事件
        item.addEventListener('click', this.doAction, true)
      })
    }
  }

  openInfoWindow() {
    if (this._controller !== null) {
      if (this.getAttribute('view-type') === 'app') {
        this._controller.action({ cmd: 'WebOpenInApp', value: '{{ENDPOINT}}/about' })
      } else {
        this._controller.action({ cmd: 'GOTO', value: 'about' })
      }
    }
  }
}

// Register custom element
if (customElements.get('chat-menu') === undefined) {
  window.customElements.define('chat-menu', Menu)
}
