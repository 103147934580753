<div class="section msg-section">
  <h2 class="reqiured-section-header">選擇問題類型</h2>
  <div class="form-control">
    <label for="mobile-business-choose">詢問業務</label>
    {{#if (eq customerType 3)}}
    <chtapp-dropdown
      id="mobile-business-choose"
      class="dropdown"
      selectindex="1"
      options='[
        { "id": 103, "name": "企客解決方案", "value": "企客解決方案" },
        { "id": 101, "name": "行動業務", "value": "行動業務" },
        { "id": 102, "name": "寬頻/市話/MOD", "value": "寬頻/市話/MOD" }
      ]'>
    </chtapp-dropdown>
    {{else}}
    <chtapp-dropdown
      id="mobile-business-choose"
      class="dropdown"
      selectindex="0"
      options='[
        { "id": 101, "name": "行動業務", "value": "行動業務" },
        { "id": 102, "name": "寬頻/市話/MOD", "value": "寬頻/市話/MOD" }
      ]'>
    </chtapp-dropdown>
    {{/if}}
  </div>
  <div class="form-control">
    <label for="mobile-question">問題類別</label>
    <chtapp-dropdown
      id="mobile-question"
      placeholder="請選擇項目"
      maxitems="5"
      class="dropdown"
      options='[
        { "id": 0, "name": "申租異動", "value": "申租異動" },
        { "id": 0, "name": "合約問題", "value": "合約問題" },
        { "id": 0, "name": "帳務諮詢", "value": "帳務諮詢" },
        { "id": 1, "name": "購機問題", "value": "購機問題" },
        { "id": 2, "name": "通訊品質(障礙)", "value": "通訊品質(障礙)" },
        { "id": 1, "name": "其他", "value": "其他" }
      ]'
    ></chtapp-dropdown>
  </div>
</div>
