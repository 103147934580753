import tooltipStyle from '../../scss/components/tooltip.scss'

export default class Tooltip extends HTMLElement {
  resizeObserver: ResizeObserver
  isDisable: boolean

  constructor() {
    super()
    this.attachShadow({ mode: 'open' })

    this.updateTooltip = this.updateTooltip.bind(this)
    this.disableTooltip = this.disableTooltip.bind(this)
    this.enableTooltip = this.enableTooltip.bind(this)

    // Create a new resize observer
    this.resizeObserver = new ResizeObserver(this.updateTooltip)
    this.isDisable = false
  }

  connectedCallback() {
    if (this.shadowRoot !== null) {
      // DOM
      this.shadowRoot.innerHTML = `
        <div class="tooltip">
          <slot></slot>
          <span class="tooltip-text">Tooltip text</span>
        </div>
      `

      // Style
      const styleElement = document.createElement('style')
      styleElement.appendChild(document.createTextNode(tooltipStyle))
      this.shadowRoot.appendChild(styleElement)

      // Setup
      // Get the target element
      const slots = this.shadowRoot.querySelectorAll('slot')
      const tooltipElement = this.shadowRoot.querySelector('.tooltip')
      const textElement = this.shadowRoot.querySelector('.tooltip-text')
      if (this.hasAttribute('text') && textElement !== null) {
        ;(textElement as HTMLElement).innerText = this.getAttribute('text') as string
      }

      slots[0].addEventListener('slotchange', this.updateTooltip)
      this.resizeObserver.observe(slots[0])
      if (tooltipElement !== null) {
        this.resizeObserver.observe(tooltipElement)
        tooltipElement.addEventListener('mouseenter', this.updateTooltip)
        tooltipElement.addEventListener('touchstart', this.disableTooltip)
        // tooltipElement.addEventListener("touchsend", this.enableTooltip);
      }
    }
  }

  disconnectedCallback() {
    this.resizeObserver.disconnect()
    if (this.shadowRoot !== null) {
      const slots = this.shadowRoot.querySelectorAll('slot')
      slots[0].removeEventListener('slotchange', this.updateTooltip)
      slots[0].removeEventListener('touchstart', this.disableTooltip)
      // slots[0].removeEventListener("touchsend", this.enableTooltip);
    }
  }

  updateTooltip() {
    console.log('updateTooltip')
    if (this.shadowRoot !== null) {
      const slots = this.shadowRoot.querySelectorAll('slot')
      const tooltipElement = this.shadowRoot.querySelector('.tooltip')
      const textElement = this.shadowRoot.querySelector('.tooltip-text')
      let nodes = slots[0].assignedElements()
      const target = nodes[0]

      if (tooltipElement !== null && textElement !== null) {
        tooltipElement.className = ['tooltip'].join(' ')

        // Set the tooltip position based on the target position
        const tooltipRect = textElement.getBoundingClientRect()
        const targetRect = target.getBoundingClientRect()
        const spaceAbove = targetRect.top
        const spaceBelow = window.innerHeight - targetRect.bottom
        const spaceLeft = targetRect.left
        const spaceRight = window.innerWidth - targetRect.right
        const tooltipWidth = tooltipRect.width
        const tooltipHeight = tooltipRect.height
        const tooltipGap = 10

        if (targetRect.width === 0 && targetRect.height === 0) {
          ;(textElement as HTMLElement).style.visibility = 'hidden'
        } else if (this.isDisable) {
          ;(textElement as HTMLElement).style.visibility = 'hidden'
        } else {
          ;(textElement as HTMLElement).style.visibility = ''
        }

        if (spaceAbove < tooltipHeight + tooltipGap) {
          tooltipElement.classList.add('bottom')
          tooltipElement.classList.remove('top')
        } else {
          tooltipElement.classList.remove('bottom')
          tooltipElement.classList.add('top')
        }

        if (spaceLeft + targetRect.width / 2 < tooltipWidth / 2) {
          tooltipElement.classList.add('left')
        } else if (spaceRight + targetRect.width / 2 < tooltipWidth / 2) {
          tooltipElement.classList.add('right')
        } else {
          tooltipElement.classList.remove('left')
          tooltipElement.classList.remove('right')
        }
      }
    }
  }
  disableTooltip() {
    this.isDisable = true
  }
  enableTooltip() {
    this.isDisable = false
  }
}
