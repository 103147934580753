<div id="enterprise-ci-4" class="section msg-section">
  <h2 class="reqiured-section-header">客戶資訊</h2>
  <small>
    為提供服務的完整性，需請您協助填寫以下資訊(若資訊不完整或不正確，可能無法處理您的留言/簡訊需求)
  </small>
  <br />
  <div class="form-control">
    <label
      for="company-name-4"
      class="reqiured-label"
    >公司名稱</label>
    <hr />
    <chtapp-input
      id="company-name-4"
      placeholder="輸入公司名稱"
      type="text"
      required="true"
      name=""
      style="width: 200px; flex-grow: 1;"
    ></chtapp-input>
  </div>
  <div class="form-control">
    <label
      for="company-id-4"
      class="reqiured-label"
    >統編</label>
    <hr />
    <chtapp-input
      id="company-id-4"
      placeholder="輸入統編8碼"
      type="tel"
      reg-validator="^[0-9]{8}$"
      name=""
      style="width: 200px; flex-grow: 1;"
    ></chtapp-input>
  </div>
  <div class="form-control">
    <label for="hn-device-4">設備號碼</label>
  </div>
  <div class="form-control" style="margin-top: 0.25rem">
    <input
      id="hn-device-4"
      placeholder="輸入HN/用戶號碼"
      required="true"
      type="text"
      name=""
      style="width: 200px"
    />
  </div>
</div>
