import fontBtnStyle from '../../scss/components/fontsizebtn.scss'
import { debounce } from '../../modules/debounce'
import { Controller } from '../modules/controller'

export enum fontSizeScale {
  small = 'small',
  medium = 'medium',
  large = 'large'
}

const sizeScales = {
  small: 1,
  medium: 1.2,
  large: 1.4
}

let getElementById = (selector: string) => {
  return document.getElementById(selector)
}

export default class FontSizeBtn extends HTMLElement {
  _fontSize: fontSizeScale
  _setupClickOutsideEvent: boolean
  _iframeElement: HTMLIFrameElement | null
  _disabled: boolean
  _debouncedCheckClickOutside: any
  _controller: Controller | null

  constructor() {
    super()
    this.attachShadow({ mode: 'open' })

    this.handlePopup = this.handlePopup.bind(this)
    this.setFontSize = this.setFontSize.bind(this)
    this.updateActiveSize = this.updateActiveSize.bind(this)
    this.checkClickOutside = this.checkClickOutside.bind(this)
    this.iconHover = this.iconHover.bind(this)
    this.iconRestore = this.iconRestore.bind(this)
    this.resetFontSize = this.resetFontSize.bind(this)

    this._debouncedCheckClickOutside = debounce(this.checkClickOutside, 300, true)

    this._fontSize = fontSizeScale.small
    this._setupClickOutsideEvent = false
    this._iframeElement = null
    this._disabled = false
    this._controller = null
  }

  connectedCallback() {
    if (this.shadowRoot !== null) {
      // DOM
      this.shadowRoot.innerHTML = this.template()

      // Style
      const styleElement = document.createElement('style')
      styleElement.appendChild(document.createTextNode(fontBtnStyle))
      this.shadowRoot.appendChild(styleElement)

      const smallBtn = this.shadowRoot.getElementById('font-small')
      const mediumBtn = this.shadowRoot.getElementById('font-medium')
      const largeBtn = this.shadowRoot.getElementById('font-large')

      // Events
      if (smallBtn !== null && mediumBtn !== null && largeBtn !== null) {
        smallBtn.addEventListener('click', this.setFontSize)
        mediumBtn.addEventListener('click', this.setFontSize)
        largeBtn.addEventListener('click', this.setFontSize)
      }
      window.addEventListener('click', this._debouncedCheckClickOutside, { capture: true })

      const rootElement = document.getElementById('chat-web-component')
      if (rootElement !== null) {
        getElementById = (selector) => {
          if (rootElement.shadowRoot !== null && rootElement.shadowRoot !== undefined) {
            return rootElement.shadowRoot.getElementById(selector)
          } else {
            return null
          }
        }
      }
      this._iframeElement = getElementById('chat-frame') as HTMLIFrameElement

      const fontSizeBtn = this.shadowRoot.querySelector('.font-size-btn')
      const btnIcon = this.shadowRoot.getElementById('btn-icon')
      const imgWrapper = this.shadowRoot.querySelector('.image-wrapper')
      if (imgWrapper !== null) {
        imgWrapper.addEventListener('click', this.handlePopup)
      }
      if (fontSizeBtn !== null) {
        if (btnIcon !== null) {
          if (this.getAttribute('view-type') === 'app') {
            ;(btnIcon as HTMLImageElement).src = '{{ENDPOINT}}/img/icon/adjust-font-size_gray.svg'
          }
          btnIcon.addEventListener('mouseenter', this.iconHover)
          btnIcon.addEventListener('mouseleave', this.iconRestore)
        }
      }
    }
  }

  disconnectedCallback() {}

  setFontSize(e: MouseEvent | TouchEvent) {
    const target = e.target as HTMLElement
    const size = target.getAttribute('data-font-size')

    // if (size === fontSizeScale.small)
    if (size !== null && Object.values(fontSizeScale).includes(size)) {
      this._fontSize = size as fontSizeScale
      this.dispatchEvent(
        new CustomEvent('sizechanged', {
          detail: sizeScales[this._fontSize]
        })
      )
      this.updateActiveSize()
      if (this._controller !== null) {
        this._controller.frameSend({
          _service: 'chtbot-js',
          type: 'clicktracking',
          data: `#介面-功能列-字級按鈕-${{ small: '小', medium: '中', large: '大' }[size]}`
        })
      }
      // this.hidePopup()
    }
  }

  updateActiveSize() {
    if (this.shadowRoot !== null) {
      const smallBtn = this.shadowRoot.getElementById('font-small')
      const mediumBtn = this.shadowRoot.getElementById('font-medium')
      const largeBtn = this.shadowRoot.getElementById('font-large')
      if (smallBtn !== null && mediumBtn !== null && largeBtn !== null) {
        if (this._fontSize === fontSizeScale.small) {
          smallBtn.classList.add('active')
          mediumBtn.classList.remove('active')
          largeBtn.classList.remove('active')
        } else if (this._fontSize === fontSizeScale.medium) {
          smallBtn.classList.remove('active')
          mediumBtn.classList.add('active')
          largeBtn.classList.remove('active')
        } else if (this._fontSize === fontSizeScale.large) {
          smallBtn.classList.remove('active')
          mediumBtn.classList.remove('active')
          largeBtn.classList.add('active')
        }
      }
    }
  }

  handlePopup() {
    if (this.shadowRoot !== null && !this._disabled) {
      const popup = this.shadowRoot.querySelector('.popup')
      const fontSizeBtn = this.shadowRoot.querySelector('.font-size-btn')
      if (popup !== null && fontSizeBtn !== null) {
        if (popup.getAttribute('data-display') === 'block') {
          popup.classList.add('hidden')
          popup.setAttribute('data-display', 'none')
        } else {
          popup.classList.remove('hidden')
          this.dispatchEvent(new CustomEvent('show-menu'))
          popup.setAttribute('data-display', 'block')
        }
        if (this._controller !== null) {
          this._controller.frameSend({
            _service: 'chtbot-js',
            type: 'clicktracking',
            data: '#介面-功能列-字級按鈕'
          })
        }
      }
    }
  }

  hidePopup() {
    if (this.shadowRoot !== null) {
      const popup = this.shadowRoot.querySelector('.popup')
      const fontSizeBtn = this.shadowRoot.querySelector('.font-size-btn')
      if (popup !== null && fontSizeBtn !== null) {
        popup.classList.add('hidden')
        popup.setAttribute('data-display', 'none')
      }
    }
  }

  checkClickOutside(e: MouseEvent | TouchEvent) {
    if (this.shadowRoot !== null) {
      const popup = this.shadowRoot.querySelector('.popup')
      if (popup !== null) {
        if (this.getAttribute('view-type') === 'embed') {
          const target = e.composedPath()[0]
          if (target !== undefined) {
            if (
              !popup.contains(target as HTMLElement) &&
              !this.shadowRoot.contains(target as HTMLElement)
            ) {
              this.hidePopup()
            }
          }
        } else if (e.target !== null) {
          const target = e.target as HTMLElement
          if (!popup.contains(target) && !this.contains(target)) {
            this.hidePopup()
          }
        }
      }
    }
  }

  iconHover() {
    if (this.shadowRoot !== null) {
      const btnIcon = this.shadowRoot.getElementById('btn-icon')
      if (btnIcon !== null) {
        ;(btnIcon as HTMLImageElement).src = '{{ENDPOINT}}/img/icon/adjust-font-size_blue.svg'
      }
    }
  }

  iconRestore() {
    if (this.shadowRoot !== null) {
      const btnIcon = this.shadowRoot.getElementById('btn-icon')
      if (btnIcon !== null) {
        if (this.getAttribute('view-type') === 'app') {
          ;(btnIcon as HTMLImageElement).src = '{{ENDPOINT}}/img/icon/adjust-font-size_gray.svg'
        } else {
          ;(btnIcon as HTMLImageElement).src = '{{ENDPOINT}}/img/icon/adjust-font-size.svg'
        }
      }
    }
  }

  resetFontSize() {
    this._fontSize = fontSizeScale.small
    this.updateActiveSize()
    this.dispatchEvent(
      new CustomEvent('sizechanged', {
        detail: sizeScales[this._fontSize]
      })
    )
  }

  set disabled(d: boolean) {
    this._disabled = d
  }

  get disabled() {
    return this._disabled
  }

  get controller() {
    return null
  }

  set controller(c: Controller | null) {
    this._controller = c
  }

  template() {
    return `
    <div class="font-size-btn">
      <div class="popup hidden">
        <h3>字級大小</h3>
        <hr />
        <div class="font-group">
          <span id="font-small"  data-font-size="small"  style="font-size: 12pt;" class="active">小</span>
          <span id="font-medium" data-font-size="medium" style="font-size: 14pt;">中</span>
          <span id="font-large"  data-font-size="large"  style="font-size: 16pt;">大</span>
        </div>
      </div>
      <div class="image-wrapper">
        <img id="btn-icon" src="{{ENDPOINT}}/img/icon/adjust-font-size.svg" alt="adjust font size" />
      </div>
    </div>
    `
  }
}
