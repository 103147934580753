<div id="cs-3" class="section msg-section">
  <h2 class="reqiured-section-header">聯絡人資訊</h2>
  <div
    class="form-control"
    style="margin-top: 0.75rem; margin-bottom: 1.5rem"
  >
    <label
      for="contact-name-3"
      class="reqiured-label"
    >姓名</label>
    <hr />
    <div style="display: flex; flex-direction: row; width: 100%; align-items: center;">
      <chtapp-input
        id="contact-name-3"
        placeholder="輸入中文姓名"
        type="text"
        required="true"
        name=""
        style="width: 200px; flex-grow: 1;"
      ></chtapp-input>
      <div style="padding: 0 8px;">
        <chtapp-radio
          id="contact-title-mister-3"
          name="contact-title-3"
          value="先生"
          text="先生"
          cancelable="true"
        ></chtapp-radio>
        <chtapp-radio
          id="contact-title-miss-3"
          name="contact-title-3"
          value="小姐"
          text="小姐"
          cancelable="true"
        ></chtapp-radio>
      </div>
    </div>
  </div>
  <div class="form-control" style="margin-bottom: 1.25rem">
    <label for="name" class="reqiured-label">聯絡方式</label>
  </div>
  <div class="form-control" style="padding-left: 0.25rem">
    <label
      for="contact-phone-3"
      class="reqiured-label"
    >聯絡方式 (行動號碼<sup>1</sup>)</label>
    <hr />
    <chtapp-input
      id="contact-phone-3"
      placeholder="輸入行動號碼"
      type="tel"
      name="contact-phone-3"
      reg-validator="^09\d{8}$"
      required="true"
      maxlength="10"
      style="width: 100%;"
    ></chtapp-input>
    <hr />
    <small>
      客服人員會透過此號碼與您聯絡
    </small>
  </div>
  <div
    id="secondContact-3"
    class="form-control"
    style="margin-bottom: 2rem; padding-left: 0.25rem;"
  >
    <label for="second-contact-3">
      第二聯絡方式 (市話或行動電話<sup>2</sup>)
    </label>
    <hr />
    <chtapp-input
      id="second-contact-3"
      placeholder="輸入其他聯絡號碼 (選填)"
      type="tel"
      name="second-contact-3"
      reg-validator="(^02[2389]\d{7}$)|(^03[35]\d{6}$)|(^03[89][1-9]\d{5}$)|(^032[0123489]\d{5}$)|(^034[1-9]\d{5}$)|(^0340[01234789]\d{4}$)|(^036[3456789]\d{5}$)|(^037[35689]\d{5}$)|(^0372[01235679]\d{4}$)|(^0374[1345678]\d{4}$)|(^0377[012345689]\d{4}$)|(^04[78][1-9]\d{5}$)|(^042[234567]\d{6}$)|(^049[23789]\d{6}$)|(^05[0125689]\d{6}$)|(^053[3456789]\d{5}$)|(^054[12356789]\d{5}$)|(^057[12346789]\d{5}$)|(^0577[1-9]\d{4}$)|(^06[123678]\d{6}$)|(^064[02][01234789]\d{4}$)|(^0644[0-8]\d{4}$)|(^065[023456789]\d{5}$)|(^069[01234589]\d{5}$)|(^07[123567]\d{6}$)|(^074[02][01234789]\d{4}$)|(^0744[0-8]\d{4}$)|(^078[012345789]\d{5}$)|(^08[03567]\d{6}$)|(^082[467][1-8]\d{4}$)|(^0823\d{5}$)|(^0840[01234789]\d{4}$)|(^0844[0-8]\d{4}$)|(^088[3-9]\d{5}$)|(^089[012358]\d{5}$)|(^089[67][023456789]\d{4}$)|(^0894[12345789]\d{4}$)|(^0899[012345789]\d{4}$)|(^0800\d{6}$)|(^080\d{7}$)|(^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$)"
      style="width: 100%"
    ></chtapp-input>
  </div>
  <div class="form-control" style="padding-left: 0.25rem">
    <label
      for="contact-email-3"
      class="reqiured-label"
    >電子信箱</label>
    <hr />
    <chtapp-input
      id="contact-email-3"
      placeholder="輸入電子信箱"
      type="email"
      name="contact-email-3"
      required="true"
      reg-validator="^(?:(\&quot;)(\&quot;[^\&quot;]+?\&quot;@)|(([0-9a-zA-Z]((\.(?!\.))|[-!#\$%&&apos;\*\+/=\?\^`\{\}\|~\w])*)(?<=[0-9a-zA-Z])@))(?:(\[)(\[(\d{1,3}\.){3}\d{1,3}\])|(([0-9a-zA-Z][-\w]*[0-9a-zA-Z]*\.)+[a-zA-Z0-9]{2,17}))$"
      style="width: 100%;"
    ></chtapp-input>
  </div>
</div>