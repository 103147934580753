<div id="cs-1" class="section msg-section">
  <h2 class="reqiured-section-header">聯絡人資訊</h2>
  <small>必要時客服人員會透過您的資料與您聯絡</small>
  <div
    class="form-control"
    style="margin-top: 0.75rem; margin-bottom: 1.5rem"
  >
    <label
      for="contact-name-1"
      class="reqiured-label"
    >姓名</label>
    <hr />
    <chtapp-input
      id="contact-name-1"
      placeholder="輸入中文姓名"
      type="text"
      required="true"
      name=""
      style="width: 200px; flex-grow: 1;"
    ></chtapp-input>
    <span class="postfix">(先生/小姐)</span>
  </div>
  <div id="contact-covid19-1" class="form-control" style="margin-bottom: 1.5rem; display: none;">
    <label
      for="covid19-isolation-1"
      class="reqiured-label"
    >家中有無居家隔離成員</label>
    <hr />
    <chtapp-dropdown
      id="covid19-isolation-1"
      placeholder="選擇家中有無隔離成員"
      maxitems="5"
      class="dropdown"
      options='[
        { "id": 0, "name": "家中「有」居家隔離成員", "value": "家中「有」居家隔離成員" },
        { "id": 1, "name": "家中「無」居家隔離成員", "value": "家中「無」居家隔離成員" }
      ]'
    ></chtapp-dropdown>
  </div>
  <div class="form-control" style="margin-bottom: 0.75rem">
    <label for="name" class="reqiured-label">聯絡方式</label>
  </div>
  <div class="form-control">
    <chtapp-radio
      id="contact-phone-radio-1"
      name="contact-1"
      value="phone"
      text="簡訊聯絡"
      checked="true"
    ></chtapp-radio>
  </div>
  <div
    id="phoneContact-1"
    class="form-control"
    style="margin-top: 0.25rem; margin-bottom: 2rem; padding-left: 1.95rem;"
  >
    <chtapp-input
      id="contact-phone-1"
      placeholder="輸入行動號碼"
      type="tel"
      name="contact-phone-1"
      required="true"
      maxlength="10"
      reg-validator="^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$"
      style="width: 100%; margin-bottom: 0.25rem;"
    ></chtapp-input>
  </div>
  <div class="form-control" style="margin-top: 1rem">
    <chtapp-radio
      id="contact-email-radio-1"
      name="contact-1"
      value="email"
      text="電子信箱"
    ></chtapp-radio>
  </div>
  <div
    id="emailContact-1"
    class="form-control"
    style="margin-top: 0.25rem; margin-bottom: 1.5rem; display: none;"
  >
    <chtapp-input
      id="contact-email-1"
      placeholder="輸入電子信箱"
      type="email"
      name="contact-email-1"
      required="true"
      reg-validator="^(?:(\&quot;)(\&quot;[^\&quot;]+?\&quot;@)|(([0-9a-zA-Z]((\.(?!\.))|[-!#\$%&&apos;\*\+/=\?\^`\{\}\|~\w])*)(?<=[0-9a-zA-Z])@))(?:(\[)(\[(\d{1,3}\.){3}\d{1,3}\])|(([0-9a-zA-Z][-\w]*[0-9a-zA-Z]*\.)+[a-zA-Z0-9]{2,17}))$"
      style="width: 100%; padding-left: 1.95rem;"
    ></chtapp-input>
  </div>
  <div
    id="secondContact-1"
    class="form-control"
    style="margin-bottom: 2rem; padding-left: 1.95rem; display: none;"
  >
    <label for="second-contact-1">
      第二聯絡方式 (行動或電話號碼)
    </label>
    <hr />
    <chtapp-input
      id="second-contact-1"
      placeholder="輸入聯絡號碼 (選填)"
      type="tel"
      name="second-contact-1"
      reg-validator="(^02[2389]\d{7}$)|(^03[35]\d{6}$)|(^03[89][1-9]\d{5}$)|(^032[0123489]\d{5}$)|(^034[1-9]\d{5}$)|(^0340[01234789]\d{4}$)|(^036[3456789]\d{5}$)|(^037[35689]\d{5}$)|(^0372[01235679]\d{4}$)|(^0374[1345678]\d{4}$)|(^0377[012345689]\d{4}$)|(^04[78][1-9]\d{5}$)|(^042[234567]\d{6}$)|(^049[23789]\d{6}$)|(^05[0125689]\d{6}$)|(^053[3456789]\d{5}$)|(^054[12356789]\d{5}$)|(^057[12346789]\d{5}$)|(^0577[1-9]\d{4}$)|(^06[123678]\d{6}$)|(^064[02][01234789]\d{4}$)|(^0644[0-8]\d{4}$)|(^065[023456789]\d{5}$)|(^069[01234589]\d{5}$)|(^07[123567]\d{6}$)|(^074[02][01234789]\d{4}$)|(^0744[0-8]\d{4}$)|(^078[012345789]\d{5}$)|(^08[03567]\d{6}$)|(^082[467][1-8]\d{4}$)|(^0823\d{5}$)|(^0840[01234789]\d{4}$)|(^0844[0-8]\d{4}$)|(^088[3-9]\d{5}$)|(^089[012358]\d{5}$)|(^089[67][023456789]\d{4}$)|(^0894[12345789]\d{4}$)|(^0899[012345789]\d{4}$)|(^0800\d{6}$)|(^080\d{7}$)|(^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$)"
      style="width: 100%"
    ></chtapp-input>
  </div>
</div>