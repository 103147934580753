<div class="section msg-section">
  <h2 class="reqiured-section-header">選擇問題類型</h2>
  <div class="form-control">
    <label for="hinet-business-choose">詢問業務</label>
    {{#if (eq customerType 3)}}
    <chtapp-dropdown
      id="hinet-business-choose"
      class="dropdown"
      selectindex="2"
      options='[
        { "id": 103, "name": "企客解決方案", "value": "企客解決方案" },
        { "id": 101, "name": "行動業務", "value": "行動業務" },
        { "id": 102, "name": "寬頻/市話/MOD", "value": "寬頻/市話/MOD" }
      ]'>
    </chtapp-dropdown>
    {{else}}
    <chtapp-dropdown
      id="hinet-business-choose"
      class="dropdown"
      selectindex="1"
      options='[
        { "id": 101, "name": "行動業務", "value": "行動業務" },
        { "id": 102, "name": "寬頻/市話/MOD", "value": "寬頻/市話/MOD" }
      ]'>
    </chtapp-dropdown>
    {{/if}}
  </div>
  <div class="form-control">
    <label for="question" @click="setFocus('question')">問題類別</label>
    <chtapp-dropdown
      id="hinet-question"
      placeholder="請選擇項目"
      class="dropdown"
      maxitems="5"
      options='[
        { "id": 0, "name": "障礙問題", "value": "障礙問題" },
        { "id": 1, "name": "新申請", "value": "新申請" },
        { "id": 2, "name": "合約問題", "value": "合約問題" },
        { "id": 2, "name": "申租異動", "value": "申租異動" },
        { "id": 3, "name": "帳務查詢", "value": "帳務查詢" }
      ]'
    ></chtapp-dropdown>
  </div>
</div>
