<div id="menu-root" class="{{genClass}}">
  <div class="menu-row">
    {{#firstRowItems}}
    <div {{#if disabled}}class="menu-item ga-target disabled"{{else}}class="menu-item ga-target"{{/if}} id="{{id}}">
      {{#unless disabled}}
      <div class="item-box">
        <img src="{{img}}" alt="" />
        <label>{{text}}</label>
      </div>
      {{/unless}}
    </div>
    {{/firstRowItems}}
  </div>
  {{#if secondRowItems.length}}
  <div class="menu-row">
    {{#secondRowItems}}
    <div {{#if disabled}}class="menu-item ga-target disabled"{{else}}class="menu-item ga-target"{{/if}} id="{{id}}">
      {{#unless disabled}}
      <div class="item-box">
        <img src="{{img}}" alt="" />
        <label>{{text}}</label>
      </div>
      {{/unless}}
    </div>
    {{/secondRowItems}}
  </div>
  {{/if}}
</div>