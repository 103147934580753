import mobileQuestionSelect from './handlebars/leaveSections/mobileQuestionSelect.hbs'
import mobileCustomerInfo1 from './handlebars/leaveSections/mobileCustomerInfo1.hbs'
import mobileCustomerInfo2 from './handlebars/leaveSections/mobileCustomerInfo2.hbs'
import mobileCustomerInfo3 from './handlebars/leaveSections/mobileCustomerInfo3.hbs'
import mobileCustomerInfo4 from './handlebars/leaveSections/mobileCustomerInfo4.hbs'
import hinetQuestionSelect from './handlebars/leaveSections/hinetQuestionSelect.hbs'
import hinetCustomerInfo1 from './handlebars/leaveSections/hinetCustomerInfo1.hbs'
import hinetCustomerInfo2 from './handlebars/leaveSections/hinetCustomerInfo2.hbs'
import hinetCustomerInfo3 from './handlebars/leaveSections/hinetCustomerInfo3.hbs'
import hinetCustomerInfo4 from './handlebars/leaveSections/hinetCustomerInfo4.hbs'
import hinetCustomerInfo5 from './handlebars/leaveSections/hinetCustomerInfo5.hbs'
import enterpriseQuestionSelect from './handlebars/leaveSections/enterpriseQuestionSelect.hbs'
import enterpriseCustomerInfo1 from './handlebars/leaveSections/enterpriseCustomerInfo1.hbs'
import enterpriseCustomerInfo2 from './handlebars/leaveSections/enterpriseCustomerInfo2.hbs'
import enterpriseCustomerInfo3 from './handlebars/leaveSections/enterpriseCustomerInfo3.hbs'
import enterpriseCustomerInfo4 from './handlebars/leaveSections/enterpriseCustomerInfo4.hbs'
import contactSection1 from './handlebars/leaveSections/contactSection1.hbs'
import contactSection2 from './handlebars/leaveSections/contactSection2.hbs'
import contactSection3 from './handlebars/leaveSections/contactSection3.hbs'
import enterpriseContactSection from './handlebars/leaveSections/enterpriseContactSection.hbs'
import enterpriseBusinessSection1 from './handlebars/leaveSections/enterpriseBusinessSection1.hbs'
import enterpriseBusinessSection2 from './handlebars/leaveSections/enterpriseBusinessSection2.hbs'
import enterpriseBusinessSection3 from './handlebars/leaveSections/enterpriseBusinessSection3.hbs'
import enterpriseBusinessSection4 from './handlebars/leaveSections/enterpriseBusinessSection4.hbs'
import problemDescription from './handlebars/leaveSections/problemDescription.hbs'
import thankSection from './handlebars/leaveSections/thankSection.hbs'

export default function (Handlebars) {
  // Register Helpers
  Handlebars.registerHelper({
    eq: (v1, v2) => v1 === v2,
    ne: (v1, v2) => v1 !== v2,
    lt: (v1, v2) => v1 < v2,
    gt: (v1, v2) => v1 > v2,
    lte: (v1, v2) => v1 <= v2,
    gte: (v1, v2) => v1 >= v2,
    and() {
      return Array.prototype.every.call(arguments, Boolean)
    },
    or() {
      return Array.prototype.slice.call(arguments, 0, -1).some(Boolean)
    }
  })

  // Register Partials
  Handlebars.registerPartial('mobileQuestionSelect', mobileQuestionSelect)
  Handlebars.registerPartial('mobileCustomerInfo1', mobileCustomerInfo1)
  Handlebars.registerPartial('mobileCustomerInfo2', mobileCustomerInfo2)
  Handlebars.registerPartial('mobileCustomerInfo3', mobileCustomerInfo3)
  Handlebars.registerPartial('mobileCustomerInfo4', mobileCustomerInfo4)
  Handlebars.registerPartial('hinetQuestionSelect', hinetQuestionSelect)
  Handlebars.registerPartial('hinetCustomerInfo1', hinetCustomerInfo1)
  Handlebars.registerPartial('hinetCustomerInfo2', hinetCustomerInfo2)
  Handlebars.registerPartial('hinetCustomerInfo3', hinetCustomerInfo3)
  Handlebars.registerPartial('hinetCustomerInfo4', hinetCustomerInfo4)
  Handlebars.registerPartial('hinetCustomerInfo5', hinetCustomerInfo5)
  Handlebars.registerPartial('enterpriseQuestionSelect', enterpriseQuestionSelect)
  Handlebars.registerPartial('enterpriseCustomerInfo1', enterpriseCustomerInfo1)
  Handlebars.registerPartial('enterpriseCustomerInfo2', enterpriseCustomerInfo2)
  Handlebars.registerPartial('enterpriseCustomerInfo3', enterpriseCustomerInfo3)
  Handlebars.registerPartial('enterpriseCustomerInfo4', enterpriseCustomerInfo4)
  Handlebars.registerPartial('contactSection1', contactSection1)
  Handlebars.registerPartial('contactSection2', contactSection2)
  Handlebars.registerPartial('contactSection3', contactSection3)
  Handlebars.registerPartial('enterpriseContactSection', enterpriseContactSection)
  Handlebars.registerPartial('enterpriseBusinessSection1', enterpriseBusinessSection1)
  Handlebars.registerPartial('enterpriseBusinessSection2', enterpriseBusinessSection2)
  Handlebars.registerPartial('enterpriseBusinessSection3', enterpriseBusinessSection3)
  Handlebars.registerPartial('enterpriseBusinessSection4', enterpriseBusinessSection4)
  Handlebars.registerPartial('problemDescription', problemDescription)
  Handlebars.registerPartial('thankSection', thankSection)
}
