import appTemplate from '../../handlebars/embed.hbs'
import resetStyle from '../css/reset.css'
import loadingStyle from '../css/loading.min.css'
import normalizeStyle from '../scss/normalize.scss'
import defaultStyle from '../scss/style.scss'
import infoWindowStyle from '../scss/infoWindow.scss'
import rwdStyle from '../scss/rwd.scss'
import embedStyle from '../scss/embed.scss'

class Embed extends HTMLElement {
  constructor() {
    super()
    this.attachShadow({ mode: 'open' })
  }

  async connectedCallback() {
    const endpoint = '{{ENDPOINT}}'
    const libPath = '{{LIBPATH}}'
    const iframeSrc = '{{IFRAME}}'

    if (this.shadowRoot !== null) {
      // DOM
      const data: {
        chatName: string
        endpoint: string
        iframe?: string
      } = { chatName: '線上客服', endpoint }
      data.iframe = ''
      this.shadowRoot.innerHTML = appTemplate(data)

      // Style
      const styleElement = document.createElement('style')
      styleElement.appendChild(document.createTextNode(resetStyle))
      styleElement.appendChild(document.createTextNode(loadingStyle))
      styleElement.appendChild(document.createTextNode(normalizeStyle))
      styleElement.appendChild(document.createTextNode(defaultStyle))
      styleElement.appendChild(document.createTextNode(infoWindowStyle))
      styleElement.appendChild(document.createTextNode(rwdStyle))
      styleElement.appendChild(document.createTextNode(embedStyle))
      this.shadowRoot.appendChild(styleElement)

      // JS
      try {
        await this.loadInlineJS(endpoint + libPath)

        await window.chatBotLib.init()

        // chatBotLib init 完成後再 load iframe
        const iframe = this.shadowRoot.getElementById('chat-frame') as HTMLIFrameElement
        if (iframe !== null) {
          // add origin GET parameter
          const url = new URL(iframeSrc)
          url.searchParams.set('origin', window.location.origin)
          url.searchParams.set('t', String(Math.floor(Date.now() / 1000)))
          iframe.src = url.toString()
        }
      } catch (error) {
        console.error(error)
      }
    }
  }

  loadInlineJS(src: string) {
    const _self = this
    return new Promise((resolve, reject) => {
      const jsTag = document.createElement('script')
      jsTag.type = 'text/javascript'
      jsTag.src = src
      jsTag.onload = () => resolve(1)
      if (_self.shadowRoot !== null) {
        _self.shadowRoot.appendChild(jsTag)
      }
    })
  }
}

// Register custom element
if (customElements.get('chat-embed') === undefined) {
  window.customElements.define('chat-embed', Embed)
}
