import queryString from 'query-string'
import appTemplate from '../../../handlebars/components/header.hbs'
import headerStyle from '../../scss/components/header.scss'

export default class Header extends HTMLElement {

  private props: {}
  private pageType: string
  private closeBtn: HTMLElement | null

  constructor() {
    super()
    this.attachShadow({ mode: 'open' })

    // member functions
    this.handleClose = this.handleClose.bind(this)

    // refs
    this.props = {
    }
    const qs = queryString.parse(location.search)
    this.pageType = String(qs.p)
    this.closeBtn = null
  }

  connectedCallback() {
    if (this.shadowRoot !== null) {
      if (this.pageType === 'embed') {
        // DOM
        this.shadowRoot.textContent = ''
      } else {
        // DOM
        this.shadowRoot.innerHTML = appTemplate(this.props)
        this.closeBtn = this.shadowRoot.getElementById('chat-disconnect')

        // Style
        const styleElement = document.createElement('style')
        styleElement.appendChild(document.createTextNode(headerStyle))
        this.shadowRoot.appendChild(styleElement)

        // Events
        if (this.closeBtn !== null) {
          this.closeBtn.addEventListener('click', this.handleClose)
        }
      }
    }
  }

  disconnectedCallback() {
    if (this.closeBtn !== null) {
      this.closeBtn.removeEventListener('click', this.handleClose)
    }
  }

  handleClose(e: Event) {
    e.preventDefault()
    if (typeof window.closeWindow === 'function') {
      window.closeWindow()
    }
  }
}
