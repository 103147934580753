export const debounce = function (
  func: (...args: any[]) => void,
  wait: number,
  immediate = false
) {
  let timeout: number | null;

  return function executedFunction(this: any, ...args: any[]) {
    const context = this;
    const later = () => {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    if (timeout !== null) {
      clearTimeout(timeout);
    }
    timeout = window.setTimeout(later, wait);
    if (callNow) {
      func.apply(context, args);
    }
  };
};
