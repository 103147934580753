import buttonStyle from '../../scss/components/button.scss'

function getStrAttribute (element: HTMLElement, attr: string) {
  if (element.hasAttribute(attr)) {
    return String(element.getAttribute(attr))
  } else {
    return ''
  }
}

export default class Button extends HTMLElement {

  private props: {
    id: string
    name: string
    disabled: boolean
    text: string
  }
  private btnRef: HTMLButtonElement | null

  constructor () {
    super()
    this.attachShadow({ mode: 'open' })

    // refs
    this.props = {
      id: '',
      name: '',
      disabled: false,
      text: ''
    }
    this.btnRef = null
  }

  connectedCallback () {
    if (this.shadowRoot !== null) {
      // Props
      this.props = {
        id: getStrAttribute(this, 'id'),
        name: getStrAttribute(this, 'name'),
        disabled: getStrAttribute(this, 'disabled') === 'true',
        text: getStrAttribute(this, 'text')
      }

      // DOM
      this.shadowRoot.innerHTML = this.template(this.props)
      this.btnRef = this.shadowRoot.querySelector('button')
      if (this.btnRef !== null) {
        this.btnRef.disabled = this.props.disabled
      }

      // Style
      const styleElement = document.createElement('style')
      styleElement.appendChild(document.createTextNode(buttonStyle))
      this.shadowRoot.appendChild(styleElement)
    }
  }

  disconnectedCallback () {
  }

  set disabled (val) {
    if (this.btnRef !== null) {
      this.props.disabled = val === true
      this.btnRef.disabled = this.props.disabled
    }
  }

  get disabled () {
    return this.props.disabled
  }

  template (data: {
    id: string
    name: string
    disabled: boolean
    text: string
  }) {
    return `
      <button
        id="${data.id}"
        class="oec-el"
        name="${data.name}"
      >
        ${data.text}
      </button>
    `
  }
}
