<div class="section msg-section">
  <h2 class="reqiured-section-header">問題敘述</h2>
  <div class="form-control" style="max-width: 100%">
    <chtapp-textarea
      id="question_description"
      name="question_description"
      {{#if (and (eq businessId "Mobile") (eq questionType "history"))}}
      placeholder="請輸入申請原因及時間"
      {{else if (and (eq businessId "FixedLine") (eq questionType "history")) }}
      placeholder="請輸入申請原因"
      {{else}}
      placeholder="請輸入並詳細描述"
      {{/if}}
      maxlength="300"
      cols="30"
      rows="10"
      style="width: 100%;"
    ></chtapp-textarea>
  </div>
  <div class="form-control">
    <chtapp-checkbox id="agree-policy" required="true">
      <span slot="label-text">已閱讀並同意<a id="privacy-link" href="#">個人資料蒐集告知聲明</a></span>
    </chtapp-checkbox>
  </div>
  <div class="form-control submit-block">
    <chtapp-button id="leave-msg-submit" text="提交"></chtapp-button>
  </div>
</div>
