<div class="section thank-section" style="display: none">
  <h2>我們已收到您的留言，後續將由客服主動與您聯繫</h2>
  <div class="reply-address">
    <span id="reply-method">聯絡方式: 簡訊聯絡</span>
    <span id="reply-address">行動號碼: 09********</span>
  </div>
  <div class="form-control">
    <img src="{{endpoint}}/img/leave-msg-delivered.svg" alt="" />
  </div>
  <div class="form-control">
    <p>如有其他問題歡迎使用Q寶諮詢</p>
  </div>
</div>
