<div id="hinet-ci-1" class="section msg-section">
  <h2 class="reqiured-section-header">客戶資訊</h2>
  <small>
    為提供服務的完整性，需請您協助填寫以下資訊(若資訊不完整或不正確，可能無法處理您的留言/簡訊需求)
  </small>
  <br />
  <div class="form-control">
    <label
      for="username1"
      class="reqiured-label"
    >姓名</label>
    <hr />
    <chtapp-input
      id="username1"
      placeholder="輸入中文姓名"
      type="text"
      required="true"
      name=""
      style="width: 200px; flex-grow: 1;"
    ></chtapp-input>
    <span class="postfix">(先生/小姐)</span>
  </div>
  <div class="form-control">
    <label
      for="hn_device1"
      class="reqiured-label"
    >設備號碼</label>
    <span class="comment-right">
      <a href="#" class="deviceid-in-bill">
        <i class="zmdi zmdi-help-outline"></i> 設備號碼範例
      </a>
    </span>
  </div>
  <div class="form-control" style="margin-top: 0.25rem">
    <chtapp-input
      id="hn_device1"
      placeholder="輸入市話/寬頻/HN號碼"
      type="text"
      required="true"
      name=""
      style="width: 200px; flex-grow: 1;"
    ></chtapp-input>
  </div>
  <div class="form-control">
    <chtapp-address
      id="install-address1"
      name="install-address1"
      text="裝機地址"
      required="true"
      style="width: 100%;"
    ></chtapp-address>
  </div>
</div>
