<div id="hinet-ci-3" class="section msg-section" style="display: none;">
  <h2 class="reqiured-section-header">客戶資訊</h2>
  <small>
    為提供服務的完整性，需請您協助填寫以下資訊(若資訊不完整或不正確，可能無法處理您的留言/簡訊需求)
  </small>
  <br />
  <div class="form-control">
    <label
      for="username3"
      class="reqiured-label"
    >姓名</label>
    <hr />
    <chtapp-input
      id="username3"
      placeholder="輸入中文姓名"
      type="text"
      required="true"
      name=""
      style="width: 200px; flex-grow: 1;"
    ></chtapp-input>
    <span class="postfix">(先生/小姐)</span>
  </div>
  <div class="form-control">
    <label
      for="hn_device3"
      class="reqiured-label"
    >設備號碼</label>
    <span class="comment-right">
      <a href="#" class="deviceid-in-bill">
        <i class="zmdi zmdi-help-outline"></i> 設備號碼範例
      </a>
    </span>
  </div>
  <div class="form-control" style="margin-top: 0.25rem">
    <chtapp-input
      id="hn_device3"
      placeholder="輸入市話/寬頻/HN號碼"
      type="text"
      required="true"
      name=""
      style="width: 200px; flex-grow: 1;"
    ></chtapp-input>
  </div>
  <div class="form-control">
    <label
      for="id-no3"
      class="reqiured-label"
    >身分證號後四碼</label>
    <hr />
    <chtapp-input
      id="id-no3"
      placeholder="輸入身分證後四碼"
      type="tel"
      reg-validator="^[0-9]{4}$"
      maxlength="4"
      name=""
      style="width: 200px; flex-grow: 1"
    />
  </div>
  <div class="form-control">
    <chtapp-address
      id="install-address3"
      name="install-address3"
      text="裝機地址"
      required="true"
      style="width: 100%;"
    ></chtapp-address>
  </div>
  <div class="form-control">
    <chtapp-checkbox id="approve-self3" required="true">
      <span slot="label-text">本人申辦</span>
    </chtapp-checkbox>
    <small ref="isselfDesciption" style="padding-left: 2rem;">
      為保護資料安全，申辦、查詢須為本人，若非本人請代辦人攜帶雙方證件臨櫃辦理。
    </small>
  </div>
</div>
