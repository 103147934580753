<div id="modal-container" class="modal-container notice">
  <div id="modal-content" class="modal-content survey-thank">
    <div class="alert-header">
      <h3>個人資料蒐集告知聲明<br />及隱私權保護政策</h3>
      <hr />
    </div>
    <div class="alert-content">
      <p>
        為保護您的權益，請詳細閱讀「個人資料蒐集告知聲明及隱私權保護政策」。
      </p>
      <p>
        線上客服提供手機、市話、ADSL、光世代等全業務客戶服務，可查詢設備資訊、帳單資訊、線上繳費，當您開始使用本服務時，將視同您已充分瞭解並同意中華電信股份有限公司
        <a
          href="https://pdpn.cht.com.tw/"
          target="_blank" rel="noopener noreferrer"
        >
          個人資料蒐集告知聲明<img class="icon" src="{{endpoint}}/img/icon/launch.svg" style="-webkit-transform: scaleX(-0.75) scaleY(0.75); transform: scaleX(-0.75) scaleY(0.75);" />
        </a>
        及
        <a
          href="https://www.cht.com.tw/home/consumer/privacy"
          target="_blank" rel="noopener noreferrer"
        >
          隱私權保護政策<img class="icon" src="{{endpoint}}/img/icon/launch.svg" style="-webkit-transform: scaleX(-0.75) scaleY(0.75); transform: scaleX(-0.75) scaleY(0.75);" />
        </a>
      </p>
    </div>
    <div class="alert-footer">
      <button class="btn btn-primary" id="hide-btn">我知道了</button>
    </div>
  </div>
</div>